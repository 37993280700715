<template>
  <div class="deviceParentView">
    <DeviceListMini
      :installation-id="installationId"
      :device-id="deviceId"
      class="mb-3"
    />
      
    <router-view />
  </div>
</template>

<script>
export default {
  name: "DeviceParentView",
  components: {
    DeviceListMini: () => import('@/components/Device/DeviceListMini.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: 'Device Detail'
    }
  }
}
</script>